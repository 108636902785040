import { useHooks } from "./useHooks";

export function useTabs() {
    const tabsColl = ref<any[]>([]);
    const hooks = useHooks();

    function activate(tabValue: any) {
        tabsColl.value.map((tab, index) => {
            tab.value.isTabActive = tab.value.tabValue == tabValue
        });

        hooks.invokeHook('change');
    }

    function register(tabChild: any) {
        tabsColl.value.push(tabChild);
    }

    function unregister(tabChild: any) {
        const index = tabsColl.value.findIndex(e => e == tabChild);
        tabsColl.value.splice(index, 1);
    }

    const onChange = (hook: any) => hooks.addHook('change', hook)

    return {
        tabsColl,
        activate,
        register,
        unregister,
        onChange
    }
}